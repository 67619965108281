import { combineReducers } from 'redux';
import chatbotReducer from './chatbotReducer';
import dfQuery from './dfQueryReducer';
import changeHeaderCss from "./headerReducer";

export default combineReducers({
    chatbotReducer,
    dfQuery,
    changeHeaderCss
});
