import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  CONVERSATION_API_URL,
  DF_EVENT_QUERY_API_URL,
  DF_TEXT_QUERY_API_URL,
  GET_GEOLOCATION_API_URL,
  SAVE_USER_DATA_LOCATION_API_URL,
  SEND_TRANSCRIPT_API_URL,
  SURVEY_DATA_API_URL,
  UPDATE_DF_CONVERSATION_API_URL,
  UUID,
} from "../../services/apiConstant";
import { getOutputContexts } from "../../utils/utils";

let messagesArray = [];
let says = {};
let inputFieldType = "text";
async function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
export function userTextQuery(text) {
  return async function (dispatch) {
    says = {
      speaks: "me",
      loader: true,
      time: await formatAMPM(new Date()),
      msg: {
        text: {
          text: text,
        },
      },
    };
    messagesArray.push(says);
    // await checkEmailValidation("type a message...", true, "#ffffff");
    dispatch({
      type: "CHANGE_DF_TEXT_QUERY_FULFILLMENT",
      payload: messagesArray,
    });
  };
}

export function dfTestQuery({ input, surveyId, uuid, setSurveyCompleted }) {
  // const uuid = localStorage.getItem("uuid");
  // console.log("dfTestQuery", uuid);
  return async function (dispatch) {
    return await axios
      .post(DF_TEXT_QUERY_API_URL, {
        text: input,
        uuid: uuid,
        surveyId: surveyId,
        parameters: { userID: uuid, language: "en-US" },
      })
      .then(async (response) => {
        console.log("response =-====>", response);
        const session =
          response.data?.data?.outputContexts[0]?.name.split("/")[4];
        console.log("seesion =====> ", session);
        const surveyCompleteContext = getOutputContexts(
          session,
          response.data.data,
          "survey_complete",
          "exact"
        );

        console.log("surveyCompleteContext===>", surveyCompleteContext);

        if (surveyCompleteContext.length > 0) {
          localStorage.setItem("surveyComplete", true);
          setTimeout(() => setSurveyCompleted(true), 5000);

          // maintain an array of surveyId that is taken
          const localSurveyId = localStorage.getItem("surveyId");

          if (!localSurveyId) {
            localStorage.setItem("surveyId", JSON.stringify([surveyId]));
          } else {
            const surveyIdArray = JSON.parse(localSurveyId);
            if (!surveyIdArray.includes(surveyId)) {
              localStorage.setItem(
                "surveyId",
                JSON.stringify(surveyIdArray.concat([surveyId]))
              );
            }
          }
        } else {
          localStorage.setItem("surveyComplete", false);
          // setSurveyCompleted(false);
        }

        if (response.data.data.fulfillmentMessages) {
          // eslint-disable-next-line array-callback-return
          says.loader = false;
          response.data.data.fulfillmentMessages.forEach((msg, index) => {
            if (msg.text) {
              if (
                msg.text.text[0].includes("mail") === true ||
                msg.text.text[0].includes("email") === true
              ) {
                // console.log("EMAIL")
                inputFieldType = "email";
              } else {
                // console.log("TEXT")
                inputFieldType = "text";
              }
            }
            says = {
              speaks: "bot",
              loader: false,
              msg: msg,
              botMessageIndex: index,
            };
            messagesArray.push(says);
          });
          localStorage.setItem("chatConvo", JSON.stringify(messagesArray));

          await axios
            .post(UPDATE_DF_CONVERSATION_API_URL, {
              // uuid: UUID,
              uuid,
              surveyId: surveyId,
              dfConversation: JSON.stringify(messagesArray),
            })
            .catch((err) => console.log("failed to update conversation", err));
          return dispatch({
            type: "CHANGE_DF_TEXT_QUERY_FULFILLMENT",
            payload: messagesArray,
            inputFieldType: inputFieldType,
          });
        } else if (response.data.webhookPayload) {
          dispatch({
            type: "CHANGE_DF_TEXT_QUERY_WEBHOOK",
            payload: response.data.data.webhookPayload,
          });
        }
      });
  };
}

export function dfEventQuery({ event, surveyId, uuid }) {
  console.log("EVENTTTTTTTT", event, surveyId, uuid);
  // const uuid = localStorage.getItem("uuid");
  return async function (dispatch) {
    return await axios
      .post(DF_EVENT_QUERY_API_URL, {
        event: event,
        uuid,
        parameters: { userID: uuid, language: "en-US" },
        surveyId: surveyId,
      })
      .then(async (response) => {
        console.log("EVENT QUERY", response);
        let respEvent = await response.data.data.fulfillmentMessages;
        says.loader = false;
        return await respEvent.map((msg, index) => {
          says = {
            speaks: "bot",
            loader: false,
            msg: msg,
            botMessageIndex: index,
          };
          messagesArray.push(says);

          // says.loaded = "true";

          return dispatch({
            type: "CHANGE_DF_EVENT_QUERY",
            payload: messagesArray,
          });
        });
      });
  };
}

export function getConversation({ messageId }) {
  return async function (dispatch) {
    return await axios
      .post(CONVERSATION_API_URL, {
        messageId,
      })
      .then(async (response) => {
        console.log("Conversation res ", response);
        if (response?.data?.data?.dfConversation) {
          dispatch({
            type: "CHANGE_MESSAGE_ARRAY",
            payload: JSON.parse(response.data.data.dfConversation),
          });
        }
      });
  };
}

export function changeHeaderCss(event) {
  return async function (dispatch) {
    dispatch({
      type: "CHANGE_HEADER_CSS",
      payload: event,
    });
  };
}

export function changeMessageArray() {
  return async function (dispatch) {
    messagesArray = messagesArray.filter((objects) => {
      if (
        objects.speaks === "bot" &&
        objects.msg &&
        objects.msg.payload &&
        objects.msg.payload.fields &&
        objects.msg.payload.fields.quick_replies
      ) {
        return null;
      } else {
        return objects;
      }
    });
    dispatch({
      type: "CHANGE_MESSAGE_ARRAY",
      payload: messagesArray,
    });
  };
}

export function changeBotState(value) {
  return async function (dispatch) {
    dispatch({
      type: "CHANGE_BOT_STATE",
      payload: value,
    });
  };
}

export function getUserLocation() {
  return async function (dispatch) {
    await axios.get(GET_GEOLOCATION_API_URL).then(async (response, err) => {
      localStorage.setItem("countryName", response.data.country_name);
      localStorage.setItem("state", response.data.state);
      localStorage.setItem("city", response.data.city);
      localStorage.setItem("IPv4", response.data.IPv4);
      console.log("response", response);
      if (response) {
        dispatch({
          type: "LOCATION_INFO",
          payload: response.data,
        });
      }
    });
  };
}

export function getSurveyInfo(id) {
  return async function (dispatch) {
    await axios.get(SURVEY_DATA_API_URL + id).then(async (response) => {
      dispatch({
        type: "SURVEY_INFO",
        payload: response && response.data.data,
      });
    });
  };
}

export function saveUserLocationandUUID(uuid) {
  // console.log("DATA SAVED IN DB")
  return async function (dispatch) {
    await axios.post(SAVE_USER_DATA_LOCATION_API_URL, {
      uuid,
      location: {
        country: localStorage.getItem("countryName"),
        state: localStorage.getItem("state"),
        city: localStorage.getItem("city"),
        IPv4: localStorage.getItem("IPv4"),
      },
    });
  };
}

export function clearConversation() {
  messagesArray = [];
  return async function (dispatch) {
    dispatch({
      type: "Clear_Conversation",
      payload: messagesArray,
    });
  };
}
export function setInputType() {
  return async function (dispatch) {
    dispatch({
      type: "CHANGE_DF_TEXT_QUERY_FULFILLMENT",
      payload: messagesArray,
      inputFieldType: "text",
    });
  };
}

export function checkEmailValidation(placeholder, inputState, setFooterColor) {
  return async function (dispatch) {
    dispatch({
      type: "CHECK_EMAIL_VALIDATION",
      payload: {
        placeholder: placeholder,
        inputState: inputState,
        setFooterColor: setFooterColor,
      },
    });
  };
}

// export function restartSurvey() {
//   clearConversation();
//   localStorage.setItem("uuid", uuidv4());

//   return async function (dispatch) {
//     dispatch(getUserLocation());
//     dispatch(saveUserLocationandUUID());
//   };
// }
