import React, { useState, useEffect } from "react";
import "./footer.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../reduxStore/actions/action";
import PropTypes from "prop-types";
import sendIcon from "../../../assests/images/send.png";
import surveyWhiteLogo from "../../../assests/images/surveyWhiteLogo.png";
import { useHistory, Redirect } from "react-router-dom";

const Footer = (props) => {
  const {
    inputFieldType,
    checkEmailValidation,
    input,
    setInput,
    surveyCompleted,
    setSurveyCompleted,
    uuid,
    isConversation,
  } = props;
  useEffect(() => {
    // if (surveyCompleted == "true") {
    //   setSurveyCompleted(true);
    // } else {
    //   setSurveyCompleted(false);
    // }
    if (inputFieldType === "email") {
      if (
        input.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ||
        input.includes("help") === true
      ) {
        checkEmailValidation("Type a message...", true, "#ffffff");
      } else {
        if (input) {
          checkEmailValidation(
            "Please provide email in correct format.",
            false,
            "#ffb3b3"
          );
        } else {
          checkEmailValidation("Type a message...", true, "#ffffff");
        }
      }
    } else {
      checkEmailValidation("Type a message...", true, "#ffffff");
    }
  }, [inputFieldType, input, checkEmailValidation]);

  function handleKeyPress(e) {
    if (
      e.key === "Enter" &&
      input !== "" &&
      props.checkEmailValidateObject.inputState === true
    ) {
      props.changeMessageArray();
      props.userTextQuery(input);
      props.dfTestQuery({
        input,
        surveyId: props.id,
        uuid,
        setSurveyCompleted,
      });
      setInput("");
    }
  }
  function onClickButton() {
    if (input !== "" && props.checkEmailValidateObject.inputState === true) {
      props.changeMessageArray();
      props.userTextQuery(input);
      props.dfTestQuery({
        input,
        surveyId: props.id,
        uuid,
        setSurveyCompleted,
      });
      setInput("");
    }
  }

  return (
    <div className="footerParent">
      <>
        <div className="footerMessageContainer">
          {!surveyCompleted && !isConversation && (
            <>
              <div className="ipDiv">
                <div className="footerInputContainer">
                  <input
                    className="sendInoutBar"
                    placeholder={props.checkEmailValidateObject.placeholder}
                    value={input}
                    type="text"
                    onChange={(evt) => {
                      setInput(evt.target.value);
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e);
                    }}
                  />
                </div>
                <div className="sendButtonContainer">
                  <button onClick={onClickButton}>
                    Send
                    <img
                      alt="imgChatbot"
                      className="send_icon"
                      src={sendIcon}
                    />
                  </button>
                </div>
              </div>
              <div className="surveyFootInfoContainer">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_NEXT_URL}`}
                >
                  {" "}
                </a>
                <p>Powered by</p>
                <img src={surveyWhiteLogo} alt="Survey White Logo" />
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
};

Footer.propTypes = {
  changeMessageArray: PropTypes.func,
  userTextQuery: PropTypes.func,
  dfTestQuery: PropTypes.func,
  inputFieldType: PropTypes.string,
  checkEmailValidateObject: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    inputFieldType: state.dfQuery.inputFieldType,
    checkEmailValidateObject: state.chatbotReducer.checkEmailValidateObject,
  };
};

export default connect(mapStateToProps, actionTypes)(Footer);
