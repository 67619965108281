import React, { useState, useEffect } from "react";
import "./header.css";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as actionTypes from "../../../reduxStore/actions/action";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import { ListGroup } from "react-bootstrap";
// import ReactToPrint from "react-to-print";
// import { dfEventQuery } from "../../../reduxStore/actions/action";
import { Grid } from "@material-ui/core";
import defaultLogo from "../../../assests/images/surveyChattyNew.png";

// import { downloadTranscript } from "../../../utils/utils";

const Header = (props) => {
  const location = useLocation();
  // const history = useHistory();
  // const dispatch = useDispatch();
  const { surveyInfo } = props;
  // const [listviewState, setListview] = useState(false);
  // const [transCss, setTransCss] = useState("trancription_icon");

  // React.useEffect(() => {
  //   console.log("ID", id, props)
  //   dispatch(actionTypes.getSurveyInfo(id));
  // }, []);

  const { surveyCompleted } = props;

  // console.log("location in chatbotbody===>", location);

  // console.log("messgaes in header ===> ", props.messages);
  // console.log("surveyInfo in header ===> ", props.surveyInfo);

  // function onMenuClick() {
  //   setListview(!listviewState);
  //   if (listviewState === true) {
  //     // setTransCss("trancription_icon_Css");
  //     props.changeHeaderCss({
  //       headerParent: "header_main",
  //     });
  //   } else {
  //     // setTransCss("trancription_icon");
  //     props.changeHeaderCss({
  //       headerParent: "header_promo",
  //     });
  //   }
  // }
  // function handleOnClickHeader() {
  //   props.changeBotState(!props.showBot);
  // }
  // function onGetTranscript() {
  //   setListview(!listviewState);
  //   setTransCss("trancription_icon");
  //   props.changeMessageArray();
  //   props.userTextQuery("transcript");
  //   props.dfTestQuery("transcript");
  // }

  // function onGetTranscript() {
  //   // const downloadTranscriptHandler = () => {
  //   //   // console.log("messages: ", props.messages);
  //   downloadTranscript(props.messages, props.surveyInfo.title);
  //   // };
  // }

  function onClickHelp() {
    props.changeMessageArray();
    props.userTextQuery("HELP");
    props.dfEventQuery({
      event: "EVENT_HELP",
      surveyId: props.id,
      uuid: props.uuid,
    });
    // setInput("");
  }
  return (
    <Grid container className="headerContainer">
      <Grid item className="headerCol1" xs={9} md={9} lg={9}>
        <div className="surveyInfoContainer">
          <div className="surveyImgContainer">
            <img
              alt="imgChatbot"
              className="header_promo_img"
              src={
                surveyInfo && surveyInfo.surveyLogo
                  ? surveyInfo.surveyLogo
                  : defaultLogo
              }
            />
          </div>
          <div className="aboutSurveyContainer">
            <h2>{surveyInfo && surveyInfo.title}</h2>
            {surveyInfo && surveyInfo.businessName.length > 0 && (
              <h4>{surveyInfo && surveyInfo.businessName}</h4>
            )}
            {/* <p>Survey Category</p> */}
          </div>
        </div>

        {/* <div className="poweredByFooter">
          <Icon style={{ verticalAlign: "middle" }}>flash_on_icon</Icon>
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#1c4fc3", fontWeight: 600, fontSize: 12 }}
            href=""
          >
            By SurveyChatty
          </a>
        </div> */}
      </Grid>
      <Grid item className="headerCol2" xs={3} md={3} lg={3}>
        {!location.pathname.includes("conversation") && !surveyCompleted && (
          <button onClick={onClickHelp}>Help</button>
        )}

        {/* <div id="trancription_icon" className={transCss} onClick={onMenuClick}> */}
        {/* {listviewState === true ? (
            <Icon style={{ fontSize: 27 }}>cancel_presentation_icon</Icon>
          ) : (
            <Icon style={{ fontSize: 27 }}>menu_icon</Icon>
          )} */}
        {/* </div> */}
        {/* {listviewState === true ? (
          <ListGroup className="listViewMenu">
            <div className="items">
               <ListGroup.Item
                style={{ paddingTop: 6, paddingBottom: 6, display: "flex" }}
                onClick={onGetTranscript}
              >
                <Icon style={{ verticalAlign: "middle", marginRight: 10 }}>
                  receipt
                </Icon>{" "}
                Download Transcript
              </ListGroup.Item>  */}

        {/* <ListGroup.Item
          style={{ paddingTop: 6, paddingBottom: 6, display: "flex" }}
          onClick={onClearConvo}
        >
          <Icon style={{ verticalAlign: "middle", marginRight: 10 }}>loop</Icon>{" "}
          Restart Survey
        </ListGroup.Item> */}
        {/* <ListGroup.Item
                style={{ paddingTop: 6, paddingBottom: 6, display: "flex" }}
                onClick={onGetTranscript}
              >
                <Icon style={{ verticalAlign: "middle", marginRight: 10 }}>
                  receipt
                </Icon>{" "}
                Download Transcript
              </ListGroup.Item> */}
        {/* <ReactToPrint
                content={() => window.print()}
                trigger={onGetTranscript}
                copyStyles
              /> */}
        {/* <ListGroup.Item
                style={{ paddingTop: 6, paddingBottom: 6, display: "flex" }}
                onClick={onClearConvo}
              >
                <Icon style={{ verticalAlign: "middle", marginRight: 10 }}>
                  clear_all
                </Icon>{" "}
                Clear Conversation
              </ListGroup.Item> 
            </div>
          </ListGroup>
        ) : null} */}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    headerClasses: state.changeHeaderCss.newCss,
    showBot: state.chatbotReducer.showBot,
    surveyInfo: state.chatbotReducer.surveyInfo,
    messages: state.dfQuery.messages,
  };
};

Header.propTypes = {
  showBot: PropTypes.bool,
  changeBotState: PropTypes.func,
  headerClasses: PropTypes.object,
  messages: PropTypes.array,
};

export default connect(mapStateToProps, actionTypes)(Header);
