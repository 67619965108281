import React, { useEffect, useMemo, useState } from "react";
import { Route } from "react-router-dom";
import Chatbot from "../../../src/components/chatbot/chatbot";
import { v4 as uuidv4 } from "uuid";
import ThankYou from "../thankyou/ThankYou";
const AllRoutes = () => {
  // const componentRef = useRef();
  const [uuid, setUuid] = useState();

  useMemo(() => {
    setUuid(uuidv4());
  }, []);

  return (
    <div>
      <Route
        exact
        path="/survey/:surveyId"
        render={() => <Chatbot uuid={uuid} />}
      ></Route>
      <Route
        exact
        path="/conversation/:surveyId/:messageId"
        render={() => <Chatbot uuid={uuid} />}
      ></Route>
      <Route
        exact
        path="/survey/:surveyId?embed=embed"
        render={() => <Chatbot uuid={uuid} />}
      ></Route>

      <Route exact path="/thankyou" render={() => <ThankYou />}></Route>
    </div>
  );
};

export default React.memo(AllRoutes);
