import React, { useEffect, useRef } from "react";
import Messgaes from "./messages/messages";
import QuickReplyButton from "./quickReplyButtons/quickReply";
import { useDispatch } from "react-redux";
import CardView from "./cardView/cardView";
import Images from "./images/images";
import "./chatbotBody.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../reduxStore/actions/action";
import PropTypes from "prop-types";
// import { v4 as uuidv4 } from "uuid";
import { getModifiedMessageArray } from "../../../utils/utils";
import surveyLogo from "../../../assests/images/surveyLogo.png";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const ChatbotBody = (props) => {
  const { setSurveyCompleted, surveyCompleted, surveyInfo } = props;
  console.log("surveyCompleted", surveyCompleted);
  const scrollToDiv = useRef();
  const changeHeaderRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    handleScrollToElement();
    dispatch(actionTypes.getUserLocation());
    dispatch(actionTypes.saveUserLocationandUUID(props.uuid));
  });

  const messageArray = getModifiedMessageArray(props.messages);

  useEffect(() => {
    handleScrollToElement();
  }, [props.messages]);

  function handleScrollToElement() {
    if (scrollToDiv) {
      setTimeout(() => {
        if (scrollToDiv && scrollToDiv.current) {
          scrollToDiv.current.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        }
      }, 500);
    }
  }
  function onScrollDiv() {
    if (changeHeaderRef.current.scrollTop >= 100) {
      props.changeHeaderCss({
        headerParent: "header_main",
      });
    } else if (changeHeaderRef.current.scrollTop <= 5) {
      props.changeHeaderCss({
        headerParent: "header_promo",
      });
    }
  }

  function renderOneMessage(message, index) {
    // console.log("RENNNNNNNDER MESSAGE", message);
    if (message.msg && message.msg.text && message.msg.text.text) {
      return (
        <Messgaes
          key={index}
          text={message.msg.text.text}
          messageBotIndex={message.botMessageIndex}
          speaker={message.speaks}
          loader={message.loader}
          time={message.time}
        />
      );
    } else if (
      message.msg &&
      message.msg.quickReplies &&
      message.msg.quickReplies.quickReplies
    ) {
      return (
        <div className="QuickReplyMainParent" key={index}>
          <Messgaes
            key={index}
            text={message.msg.quickReplies.title}
            messageBotIndex={message.botMessageIndex}
            speaker={message.speaks}
            loader={message.loader}
            time={message.time}
          />
          <div className="quickReplyContainer">
            <QuickReplyButton
              payload={message.msg.quickReplies.quickReplies}
              surveyId={props?.id}
              isConversation={props.isConversation}
              uuid={props.uuid}
              setSurveyCompleted={setSurveyCompleted}
            ></QuickReplyButton>
          </div>
        </div>
      );
    } else if (message.msg && message.msg.image && message.msg.image.imageUri) {
      // console.log("IMAGE IMAGE", message.msg.image.imageUri);
      return <Images key={index} payload={message.msg.image}></Images>;
    } else if (message.msg && message.msg.card) {
      let width = 2 * 78 * 2;

      return (
        <div className="cardCon">
          <CardView
            key={index}
            messageBotIndex={message.botMessageIndex}
            payload={message.msg}
            width={width}
            surveyId={props?.id}
            isConversation={props.isConversation}
            uuid={props.uuid}
            setSurveyCompleted={setSurveyCompleted}
          ></CardView>
        </div>
      );
    }
  }

  function renderMessages(stateMessages) {
    if (stateMessages) {
      // console.log("stateMessages====> ", stateMessages);
      return (
        <div className="allFulfillmentParent">
          {stateMessages.map((message, i) => {
            return renderOneMessage(message, i);
          })}
        </div>
      );
    }
    return null;
  }

  function surveyCompletedMessages() {
    return (
      <div className="surveyCompleted">
        <div>
          <img alt="imgChatbot" src={surveyLogo} className="surveyLogo" />
        </div>
        <h1 className="thankLine">Thank you for taking this survey. </h1>
        <p className="lineUp">
          See how easy it is to create an AI based survey
        </p>

        <Button
          className="quickButton createSurveyBtn"
          onClick={() => {
            window.open(`${process.env.REACT_APP_NEXT_URL}`, "_blank");
          }}
        >
          {" "}
          Create your survey
        </Button>
      </div>
    );
  }

  console.log("messages Array===>", messageArray);

  return (
    <>
      <div
        id="chatbot_body"
        className="chatbot_body"
        ref={changeHeaderRef}
        onScroll={onScrollDiv}
      >
        {surveyCompleted == true ? (
          surveyCompletedMessages()
        ) : messageArray.length === 0 && props.isConversation ? (
          <div className="surveyCompleted">
            <div>
              <img alt="imgChatbot" src={surveyLogo} className="surveyLogo" />
            </div>
            <h1 className="thankLine">No User Response</h1>
          </div>
        ) : (
          renderMessages(messageArray)
        )}
        <div
          className="scrollToDivClass"
          style={{ width: "100%", height: 10 }}
          ref={scrollToDiv}
        ></div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.dfQuery.messages,
    loaderValue: state.dfQuery.showLoader,
  };
};

ChatbotBody.propTypes = {
  changeHeaderCss: PropTypes.func,
  messages: PropTypes.array,
  dfEventQuery: PropTypes.func,
};

export default connect(mapStateToProps, actionTypes)(ChatbotBody);
