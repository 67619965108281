import React from "react";
import "./images.css";

const Images = (props) => {
  return (
    <div className="messageTextBot">
      <div className="imagesStyling">
        <img src={props.payload.imageUri} alt={props.payload.imageUri}></img>
      </div>
    </div>
  );
};

export default Images;
