import React from "react";
import "./cardView.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actionTypes from "../../../../reduxStore/actions/action";
import Messages from "../messages/messages";
import botImg from "../../../../assests/images/chatbot/bot.png";

const CardView = (props) => {
  const { payload, width, setSurveyCompleted } = props;
  const { card } = payload;
  function redirect(link) {
    window.open(link, "_blank");
  }

  console.log("payload in card", payload);

  async function onclickHandler(buttonText) {
    props.changeMessageArray();
    props.userTextQuery(buttonText);
    await props.dfTestQuery({
      input: buttonText,
      surveyId: props?.surveyId,
      uuid: props.uuid,
      setSurveyCompleted,
    });
  }

  const cardParentClass = payload.card.disabled
    ? "allCardParent disableCardParent"
    : "allCardParent";
  return (
    <div>
      {props.messageBotIndex === 0 ? (
        <div className="messageImg">
          <img
            // style={{ background: "#e6e6e6" }}
            className="message_bot_logo"
            src={botImg}
            // "https://surveyassistant.online//webtheme/img/logouser.png"
            alt="bot"
          ></img>
        </div>
      ) : null}

      <div className="card_view_parent ">
        <div id="chatbot_body" className="card_main_body ">
          <div className={cardParentClass}>
            {card && (
              <div
                className="singleCardParent"
                onClick={
                  props.isConversation
                    ? null
                    : () => {
                        if (payload.card?.disabled) return;
                        onclickHandler(card.title);
                      }
                }
              >
                <div className="singleCardParentIn">
                  <img
                    className="card_image"
                    alt={card.imageUri}
                    src={card.imageUri}
                  ></img>
                </div>
                {/* <div className="card_content">
                  <h5 className="h5CardContent">{card.title}</h5>
                </div> */}
                {card?.structValue &&
                card.structValue.fields.type &&
                card.structValue.fields.type.stringValue === "withButton" ? (
                  <div
                    className="card_content_button"
                    onClick={
                      props.isConversation
                        ? null
                        : () =>
                            redirect(card.structValue.fields.link.stringValue)
                    }
                  >
                    <h5 className="h5CardContent_button">
                      {card.structValue.fields.button.stringValue}
                    </h5>
                  </div>
                ) : card && card.title ? (
                  <div
                    className="card_content_button"
                    // onClick={
                    //   props.isConversation
                    //     ? null
                    //     : () => onclickHandler(card.title)
                    // }
                  >
                    <h5 className="h5CardContent_button">{card.title}</h5>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CardView.propTypes = {
  payload: PropTypes.array,
  width: PropTypes.number,
};

export default connect(null, actionTypes)(CardView);
