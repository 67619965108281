const initialState = {
  showBot: true,
  surveyInfo: {},
  checkEmailValidateObject: {
    placeholder: "Type a message...",
    inputState: true,
    setFooterColor: "#ffffff",
  },
  locationInfo: {},
};

const chatbotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_BOT_STATE":
      console.log("CHAT_WINDOW_STATE_CHANGE");
      return {
        ...state,
        showBot: action.payload,
      };
    case "CHECK_EMAIL_VALIDATION":
      return {
        ...state,
        checkEmailValidateObject: action.payload,
      };
    case "SURVEY_INFO":
      return {
        ...state,
        surveyInfo: action.payload,
      };
    case "LOCATION_INFO":
      return {
        ...state,
        locationInfo: action.payload,
      };

    default:
      return state;
  }
  // return state;
};
export default chatbotReducer;
