import React from "react";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import SurveyChattyLogo from "../../../assests/images/surveyLogo.png";
import "./mainHeader.css";

export default function MainHeader() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  console.log(
    "get link check===>",
    `${process.env.REACT_APP_NEXT_URL}/planPricing`
  );

  return (
    <div className="mainHeaderContainer">
      <Grid container className="mainHeaderBlock">
        <Grid item sm={12} md={4} lg={4} className="mainHeaderCol1">
          <img src={SurveyChattyLogo} alt="logo" />
        </Grid>
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <Grid item  sm={12} md={8} lg={8}
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <Grid item sm={12} md={12} lg={7} className="mainHeaderCol2">
            <ul>
              <li>
                <a
                  href={`${process.env.REACT_APP_NEXT_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/aboutUs`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_NEXT_URL}/planPricing`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Plan & Pricing
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_NEXT_URL}/contact`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item sm={12} md={12} lg={5} className="mainHeaderCol3">
            <ul>
              <li>
                <a
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Log In
                </a>
              </li>
              <li className="none">|</li>
              <li>
                <a
                  href={`${process.env.REACT_APP_NEXT_URL}/planPricing`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Up
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
//export default MainHeader;
