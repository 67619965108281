
const initialState = {
    messages: [],
    inputFieldType: "text"
};

const dfQueryReducer = (state = initialState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case "CHANGE_DF_TEXT_QUERY_FULFILLMENT":
            console.log("MESSAGE_FULFILLMENT");

            return {
                ...state,
                inputFieldType: action.inputFieldType,
                messages: [...action.payload],
            };
        case "CHANGE_DF_TEXT_QUERY_WEBHOOK":
            console.log("MESSAGE_WEBHOOK");
            return {
                ...state,
                messages: [...action.payload]
            };
        case "CHANGE_DF_EVENT_QUERY":
            console.log("MESSAGE_EVENT_QUERY");
            return {
                ...state,
                messages: [...action.payload]
            };
        case "CHANGE_MESSAGE_ARRAY":
            console.log("MESSAGE_ARRAY_UPDATED");
            return {
                ...state,
                messages: [...action.payload]
            };

        case "DB_MESSAGES":
            console.log("MESSAGE_ARRAY_UPDATED");
            return {
                ...state,
                messages: action.payload
            };
        case "Clear_Conversation":

            return {
                ...state,
                messages: [...action.payload]
            };


    }
    return state;
};
export default dfQueryReducer;
