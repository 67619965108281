import { ExportToCsv } from "export-to-csv";

const transformMessage = (messageArray) => {
  console.log("messageArray==>", messageArray);
  //   let flatMessages = messageArray.map((data, idx) => {
  //     return {
  //       bot:
  //         data?.msg?.message === "text"
  //           ? data?.msg?.text?.text[0]
  //           : data?.msg?.message === "quickReplies"
  //           ? data?.msg?.quickReplies?.title
  //           : "",
  //       user: data?.speaks === "me" ? data?.msg?.text?.text : "",
  //     };
  //   });

  //   let flatMessages = messageArray.map((data, idx) => {
  //     return {
  //       bot:
  //         data?.msg?.message === "text"
  //           ? data?.msg?.text?.text[0]
  //           : data?.msg?.message === "quickReplies"
  //           ? data?.msg?.quickReplies?.title
  //           : "",
  //       user: data?.speaks === "me" ? data?.msg?.text?.text : "",
  //     };
  //   });

  let flatMessages = messageArray.map((data) => ({
    bot:
      data?.msg?.message === "text"
        ? data?.msg?.text?.text[0]
        : data?.msg?.message === "quickReplies"
        ? data?.msg?.quickReplies?.title
        : "",
    user: data?.speaks === "me" ? data?.msg?.text?.text : "",
  }));

  return flatMessages;
};

export const downloadTranscript = (messages, surveyName) => {
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    filename: surveyName,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ["user_id", "question", "user_response"],
  };

  const csvExporter = new ExportToCsv(options);

  let transformedMessage = [];

  if (messages && messages.length > 0) {
    const transformedMessage = transformMessage(messages);
    console.log("transformedMessage ===>", transformedMessage);
    // transformedTranscript = [...transformedTranscript, ...transformedMessage];

    // console.log("transformedTranscript", transformedTranscript);
  }

  csvExporter.generateCsv(transformedMessage);
};

const setDisabledTrueQuickReplies = (quickRepliesArray) => {
  return quickRepliesArray.map((reply) => ({ reply, disabled: true }));
};

const setDisabledFalseQuickReplies = (quickRepliesArray) => {
  return quickRepliesArray.map((reply) => ({ ...reply, disabled: false }));
};

const getMessagesWithDisabledStatus = (messagesArray) => {
  const modifiedMessagesArray = messagesArray.map((message) => {
    console.log("message===>", message);
    if (
      message.speaks === "bot" &&
      message.msg?.quickReplies &&
      message.msg?.quickReplies?.quickReplies
    ) {
      return {
        ...message,
        msg: {
          ...message.msg,
          quickReplies: {
            ...message.msg.quickReplies,
            quickReplies: setDisabledTrueQuickReplies(
              message.msg.quickReplies.quickReplies
            ),
          },
        },
      };
    } else if (message.speaks === "bot" && message.msg?.card) {
      return {
        ...message,
        msg: {
          ...message.msg,
          card: {
            ...message.msg.card,
            disabled: true,
          },
        },
      };
    } else {
      return message;
    }
  });

  return modifiedMessagesArray;
};

export const getModifiedMessageArray = (messagesArray) => {
  const modifiedMessagesArray = getMessagesWithDisabledStatus(messagesArray);

  console.log("mmodifiedMessagesArray===>", modifiedMessagesArray);

  // const lastIndexOfQuickReplies = modifiedMessagesArray.findLastIndex(
  //   (message) =>
  //     message.speaks === "bot" &&
  //     message.msg?.quickReplies &&
  //     message.msg?.quickReplies?.quickReplies
  // );

  let lastIndexOfQuickReplies;
  for (let i = modifiedMessagesArray.length - 1; i >= 0; i--) {
    if (
      modifiedMessagesArray[i].speaks === "bot" &&
      modifiedMessagesArray[i].msg?.quickReplies &&
      modifiedMessagesArray[i].msg?.quickReplies?.quickReplies
    ) {
      lastIndexOfQuickReplies = i;
      break;
    } else {
      lastIndexOfQuickReplies = -1;
    }
  }

  // const lastIndexOfCard = modifiedMessagesArray.findLastIndex(
  //   (message) =>
  //     message.speaks === "bot" &&
  //     message.msg?.card &&
  //     message.msg?.card?.disabled
  // );

  let lastIndexOfCard;
  for (let i = modifiedMessagesArray.length - 1; i >= 0; i--) {
    if (
      modifiedMessagesArray[i].speaks === "bot" &&
      modifiedMessagesArray[i].msg?.card &&
      modifiedMessagesArray[i].msg?.card?.disabled
    ) {
      lastIndexOfCard = i;
      break;
    } else {
      lastIndexOfCard = -1;
    }
  }

  // const lastIndexOfMe = modifiedMessagesArray.findLastIndex(
  //   (message) => message.speaks === "me"
  // );

  let lastIndexOfMe;
  for (let i = modifiedMessagesArray.length - 1; i >= 0; i--) {
    if (modifiedMessagesArray[i].speaks === "me") {
      lastIndexOfMe = i;
      break;
    } else {
      lastIndexOfMe = -1;
    }
  }

  if (
    modifiedMessagesArray.length > 2 &&
    modifiedMessagesArray[2].msg?.card &&
    lastIndexOfMe === -1
  ) {
    let indexOfCard = 2;

    while (indexOfCard < modifiedMessagesArray.length) {
      console.log("in while condition", indexOfCard);
      modifiedMessagesArray[indexOfCard] = {
        ...modifiedMessagesArray[indexOfCard],
        msg: {
          ...modifiedMessagesArray[indexOfCard].msg,
          card: {
            ...modifiedMessagesArray[indexOfCard].msg.card,
            disabled: false,
          },
        },
      };

      indexOfCard++;
    }
  } else if (
    modifiedMessagesArray.length > lastIndexOfMe + 2 &&
    lastIndexOfMe !== -1 &&
    modifiedMessagesArray[lastIndexOfMe + 2].msg?.card
  ) {
    let indexOfCard = lastIndexOfMe + 2;
    while (indexOfCard < modifiedMessagesArray.length) {
      modifiedMessagesArray[indexOfCard] = {
        ...modifiedMessagesArray[indexOfCard],
        msg: {
          ...modifiedMessagesArray[indexOfCard].msg,
          card: {
            ...modifiedMessagesArray[indexOfCard].msg.card,
            disabled: false,
          },
        },
      };

      indexOfCard++;
    }
  } else if (
    modifiedMessagesArray.length > lastIndexOfMe + 3 &&
    lastIndexOfMe !== -1 &&
    modifiedMessagesArray[lastIndexOfMe + 3].msg?.card
  ) {
    let indexOfCard = lastIndexOfMe + 3;
    while (indexOfCard < modifiedMessagesArray.length) {
      modifiedMessagesArray[indexOfCard] = {
        ...modifiedMessagesArray[indexOfCard],
        msg: {
          ...modifiedMessagesArray[indexOfCard].msg,
          card: {
            ...modifiedMessagesArray[indexOfCard].msg.card,
            disabled: false,
          },
        },
      };

      indexOfCard++;
    }
  }

  console.log("lastIndexOfQuickReplies ===>", lastIndexOfQuickReplies);
  console.log("lastIndexOfCard ===>", lastIndexOfCard);
  console.log("lastIndexOfMe ===>", lastIndexOfMe);
  console.log(
    "modifiedMessagesArray[lastIndexOfQuickReplies]",
    modifiedMessagesArray[lastIndexOfQuickReplies]
  );

  if (
    lastIndexOfQuickReplies !== -1 &&
    modifiedMessagesArray.length - 1 === lastIndexOfQuickReplies
  ) {
    modifiedMessagesArray[lastIndexOfQuickReplies] = {
      ...modifiedMessagesArray[lastIndexOfQuickReplies],
      msg: {
        ...modifiedMessagesArray[lastIndexOfQuickReplies].msg,
        quickReplies: {
          ...modifiedMessagesArray[lastIndexOfQuickReplies].msg.quickReplies,
          quickReplies: setDisabledFalseQuickReplies(
            modifiedMessagesArray[lastIndexOfQuickReplies].msg.quickReplies
              .quickReplies
          ),
        },
      },
    };
  }

  console.log("mmmmmmodified messages", modifiedMessagesArray);

  return modifiedMessagesArray;
};

export const getOutputContexts = (
  session,
  queryResult,
  name,
  type = "exact"
) => {
  let outputContexts= [];
  if (type === "exact")
    outputContexts = queryResult.outputContexts.filter((context) =>
      context["name"].includes(`${session}/contexts/${name}`)
    );
  else if (type === "match")
    outputContexts = queryResult.outputContexts.filter(
      (context) =>
        context["name"].indexOf(`${session}/contexts/${name}`) > -1
    );

  return outputContexts;
};
