import React from "react";
import Button from "react-bootstrap/Button";
import "./quickReply.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../../reduxStore/actions/action";
// import { getModifiedMessageArray } from "../../../../utils/utils";

function QuickReplyButton(props) {
  console.log("messagesArray ====>", props.messages);

  // const messageArray = getModifiedMessageArray(props.messages);

  // console.log("messageArray ====>", messageArray);
  async function onclickHandler(buttonText) {
    props.changeMessageArray();
    props.userTextQuery(buttonText);
    await props.dfTestQuery({
      input: buttonText,
      surveyId: props.surveyId,
      uuid: props.uuid,
      setSurveyCompleted: props.setSurveyCompleted,
    });
  }

  console.log("payload", props.payload);

  return props.payload.map((quickReplies, index) => {
    console.log("quickReplies quickReplies", quickReplies);
    return (
      <div
        id={quickReplies.reply}
        key={index}
        className={
          quickReplies === "large"
            ? "quickButtonDiv col-md-11 col-11"
            : "quickButtonDiv col-sm-12 col-md-3 col-lg-3 col-sm-6"
        }
      >
        <Button
          className="quickButton"
          onClick={
            props.isConversation
              ? null
              : () => onclickHandler(quickReplies.reply)
          }
          disabled={quickReplies.disabled}
        >
          <div className="button_text">{quickReplies.reply}</div>
        </Button>
      </div>
    );
  });
}

const mapStateToProps = (state) => {
  return {
    messages: state.dfQuery.messages,
  };
};

export default connect(mapStateToProps, actionTypes)(QuickReplyButton);
