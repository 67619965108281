// export const BASE_URL = "https://assistant-service-dnug3rszqa-uc.a.run.app";

// export const BASE_URL =
//   "https://surveyassistant-354008.uc.r.appspot.com/api/assistant";

// export const BASE_URL = "https://surveyassistant-dnrd.uc.r.appspot.com/api/assistant";
// export const BASE_URL = "http://localhost:5004";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const DF_TEXT_QUERY_API_URL = BASE_URL + "/df_text_query";
export const SEND_TRANSCRIPT_API_URL = BASE_URL + "/send_transcript";
export const CONVERSATION_API_URL = BASE_URL + "/conversation";
export const UPDATE_DF_CONVERSATION_API_URL =
  BASE_URL + "/update_df_conversation";
export const DF_EVENT_QUERY_API_URL = BASE_URL + "/df_event_query";
export const DB_MESSAGES_API_URL = BASE_URL + "/conversation";
export const GET_GEOLOCATION_API_URL = "https://geolocation-db.com/json/";
export const SURVEY_DATA_API_URL = BASE_URL + `/survey-info/`;
export const SAVE_USER_DATA_LOCATION_API_URL =
  BASE_URL + "/saveUserDataLocation";
export const UUID = localStorage.getItem("uuid");

// export const DF_TEXT_QUERY_API_URL = BASE_URL + "assistant/df_text_query";
// export const SEND_TRANSCRIPT_API_URL = BASE_URL + "assistant/send_transcript";
// export const DF_EVENT_QUERY_API_URL = BASE_URL + "assistant/df_event_query";
// export const GET_GEOLOCATION_API_URL = "https://geolocation-db.com/json/";
// export const SURVEY_DATA_API_URL = BASE_URL + `assistant/survey-data/`;
// export const SAVE_USER_DATA_LOCATION_API_URL =
//   "http://localhost:5004/api/saveUserDataLocation";
