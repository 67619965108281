import React from "react";
import Icon from "@material-ui/core/Icon";
import "./messages.css";
import "./messageLoader.css";
import PropTypes from "prop-types";
import botImg from "../../../../assests/images/assistant.png";
import userImg from "../../../../assests/images/user.png";

function Messages(props) {
  const { loader, speaker, text, messageBotIndex } = props;

  let msg;
  let loaderDiv = false;
  if (loader === true) {
    loaderDiv = (
      <div className="loaderMessageBox">
        <div className="messageImg">
          <img
            className="message_bot_logo"
            src={botImg}
            // "https://surveyassistant.online//webtheme/img/logouser.png"
            alt="bot"
          ></img>
        </div>
        <div className="messageTextBot loaderMessage">
          <div className="canvas">
            <div className="blue ball" />
            <div className="red ball" />
            <div className="yellow ball" />
          </div>
        </div>
      </div>
    );
  } else {
    loaderDiv = null;
  }
  // if (speaker === "bot" && messageBotIndex === 0) {
  if (text !== undefined && speaker === "bot") {
    // // AI based survey
    // let isAIChatPresent = false;
    // let aiText = text;
    // console.log("text===>", text, typeof text);
    // if (
    //   typeof text === "object" &&
    //   text[0].includes("create an AI based survey")
    // ) {
    //   console.log("text[0]", text[0], typeof text[0]);
    //   aiText = text[0].split("create an AI based survey.");
    //   isAIChatPresent = true;
    // }

    let isContactPresent = false;
    let contactText = text;

    if (
      typeof text === "object" &&
      text[0].includes("https://surveychatty.com/contact")
    ) {
      contactText = "https://surveychatty.com/contact";
      isContactPresent = true;
    }
    msg = (
      <div>
        <div className="messageImg">
          <img
            // style={{background:'#e6e6e6'}}
            className="message_bot_logo"
            src={botImg}
            alt="bot"
          ></img>
        </div>
        <div className="messageTextBot messageTextBotWithIcon">
          <div id="botMsg" className="bot-message z-depth-1">
            <div className="black-text">
              {!isContactPresent && text}{" "}
              {/* {isAIChatPresent && (
                <a
                  href="https://survey-dashboard-dot-surveyassistant-354008.uc.r.appspot.com/"
                  target="_blank"
                >
                  create an AI based survey.
                </a>
              )} */}
              {isContactPresent && (
                <>
                  <p style={{ display: "inline" }}>
                    Please click on the given link:{" "}
                  </p>
                  <a
                    href={contactText}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contactText}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (text !== undefined && speaker === "bot") {
    msg = (
      <div className="messageTextBot ">
        <div id="botMsg" className="bot-message z-depth-1">
          <div className="black-text">{text}</div>
        </div>
      </div>
    );
  } else if (speaker === "me") {
    msg = (
      <div>
        <div className="messageImgICONUser">
          {/* <Icon className="userPersonLogo">person</Icon> */}
          {/* <img
            className="message_bot_logo"
            style={{float:'right'}}
            // src={require("../../../../assests/images/chatbot/msd-01-icon.png")}
            src="https://www.cogniaim.com/webtheme/img/products/userLogo.png"
            alt="bot"
          ></img> */}
          <img src={userImg} alt="" className="message-user-logo" />
        </div>
        <div className="messageTextUser">
          <div className="user-message z-depth-1">
            <div className="white-text">{text}</div>
          </div>
          {/* <div className="timmer">{time}</div> */}
        </div>
      </div>
    );
  }

  return (
    <div className={speaker === "bot" ? "Botmessages" : "Usermessages"}>
      {msg}
      {loaderDiv}
    </div>
  );
}

Messages.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.instanceOf(Messages),
  ]),
  loader: PropTypes.bool,
  speaker: PropTypes.string,
  time: PropTypes.string,
};

export default Messages;
