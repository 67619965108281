import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Header from "../chatbot/Header/header";
import ChatbotBody from "./chatbotBody/chatbotBody";
import Footer from "./Footer/footer";
import { useHistory, useParams } from "react-router-dom";
import "./chatbot.css";
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as actionTypes from "../../reduxStore/actions/action";
import MainHeader from "./mainHeader/mainHeader";
import disabled from "../../assests/images/disabled.png";
import loaderGif from "../../assests/images/blueAiLoader.gif";

function Chatbot(props) {
  const [input, setInput] = useState("");
  const [surveyCompleted, setSurveyCompleted] = useState();
  const { surveyId, messageId } = useParams();
  const history = useHistory();
  const location = useLocation();
  console.log("location===>", location);
  let { surveyInfo, uuid } = props;

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  let dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      // const abc = localStorage.getItem("surveyComplete");
      const localSurveyId = localStorage.getItem("surveyId");
      let surveyIdArray = [];
      if (localSurveyId) {
        surveyIdArray = JSON.parse(localSurveyId);
      }

      if (
        localStorage.getItem("surveyComplete") == "true" &&
        surveyIdArray.includes(surveyId)
      ) {
        // setTimeout(() => setSurveyCompleted(true), 5000);
        setSurveyCompleted(true);
      } else {
        setSurveyCompleted(false);
      }

      if (location.pathname.includes("conversation")) {
        localStorage.setItem("surveyComplete", "false");
        setSurveyCompleted(false);
      }

      if (messageId && surveyId) {
        dispatch(actionTypes.getConversation({ messageId }));
      } else if (surveyId) {
        let search = "?uuid=" + uuid;
        if (query.get("embed") === "embed") {
          search = "?uuid=" + uuid + "&embed=embed";
        } else if (query.get("test") === "test") {
          search = "?uuid=" + uuid + "&test=test";
          localStorage.setItem("surveyComplete", "false");
          setSurveyCompleted(false);
        }
        history.replace({
          pathname: location.pathname,
          search,
        });
        await dispatch(
          actionTypes.dfEventQuery({
            event: "WELCOME",
            surveyId: surveyId,
            uuid,
          })
        );
        await dispatch(
          actionTypes.dfEventQuery({
            event: "SECOND_EVENT",
            surveyId: surveyId,
            uuid,
          })
        );
      }
    })();
  }, []);

  React.useEffect(() => {
    dispatch(actionTypes.getSurveyInfo(surveyId));
  }, []);

  const chatWindowClass = `${
    query.get("embed") === "embed"
      ? "chat-window-div iframe-chat-window"
      : "chat-window-div"
  }`;

  return (
    <div className={chatWindowClass}>
      {query.get("embed") !== "embed" && <MainHeader />}
      {(() => {
        if (surveyInfo && Object.keys(surveyInfo).length > 0) {
          if (surveyInfo.isActive) {
            return (
              <div className="chat-window">
                <Header
                  id={surveyId}
                  surveyInfo={surveyInfo}
                  input={input}
                  surveyCompleted={surveyCompleted}
                  uuid={uuid}
                />
                <ChatbotBody
                  id={surveyId}
                  isConversation={messageId ? true : false}
                  uuid={uuid}
                  setSurveyCompleted={setSurveyCompleted}
                  surveyCompleted={surveyCompleted}
                  surveyInfo={surveyInfo}
                />
                <Footer
                  id={surveyId}
                  isConversation={messageId ? true : false}
                  input={input}
                  setInput={setInput}
                  surveyCompleted={surveyCompleted}
                  setSurveyCompleted={setSurveyCompleted}
                  uuid={uuid}
                />
              </div>
            );
          } else {
            return (
              <div className="disabledSurveyContainer">
                <img src={disabled} alt="Disabled Survey" />
                <h3 style={{ padding: "10px 0px", fontWeight: "700" }}>
                  This survey is temporarily disabled.
                </h3>
                <h3
                  style={{
                    fontWeight: "700",
                    marginBottom: "10px",
                    paddingTop: "30px",
                  }}
                >
                  Want to create your own survey?
                </h3>
                <a href={`${process.env.REACT_APP_NEXT_URL}/planPricing`}>
                  Sign Up
                </a>
              </div>
            );
          }
        } else {
          return (
            <div className="loaderContainer">
              <img src={loaderGif} alt="Survey Loading" />
              <p>Loading....</p>
            </div>
          );
        }
      })()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    showBot: state.chatbotReducer.showBot,
    surveyInfo: state.chatbotReducer.surveyInfo,
  };
};

Chatbot.propTypes = {
  showBot: PropTypes.bool,
};

export default connect(mapStateToProps, actionTypes)(Chatbot);
