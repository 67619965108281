const initialState = {
    newCss: {
        headerParent: "header_promo"
    },
};
const changeHeaderCss = (state = initialState, action) => {

    switch (action.type) {
        case "CHANGE_HEADER_CSS":
            return {
                ...state,
                newCss: action.payload
            };
            default:
                return state
    }
    // return state;
};
export default changeHeaderCss;
